import { useState, useContext } from 'react';
import { Mycontext } from './App';
const Gmb = () => {
    const rcd = useContext(Mycontext);
    const { state, dispatch } = rcd;
    const [que3, Setque3] = useState([]);
    const ValueChange = (evt) => {
        fetch('http://api.adortatechnologies.com/token/index.php')
            .then((response) => response.json())
            .then((data) => {
                //Setque3(data.results);
                console.log(data.locationDrivingDirectionMetrics);
                dispatch({ type: "ADDED", payload: data.results });

            });
        
    }

    return (
        <div className="container">
            <h1>Question 3</h1>
            <select onChange={ValueChange} >
                <option defaultValue>--choose --</option>
                <option value="7">1 Week</option>
                <option value="30">1 Month</option>
                <option value="90">1 Quater</option>
            </select>

          
            <br />
            <div className="row">

                {
                    que3.map((que3one, i) => {
                        return (
                            <div key={i} className="col-sm-3">
                                <div className="grid">
                                    <img src={que3one.picture.large} /><br />
                                    <p><span className="name">Name: </span> {que3one.name.title} {que3one.name.first} {que3one.name.last} </p>
                                    <p><span className="name">Location:</span> {que3one.location.city} </p>
                                    <p><span className="name">Country:</span> {que3one.location.country}</p>
                                </div>
                            </div>

                        );
                    })

                }
                <br />
            </div>
        </div>

    );
}
export default Gmb;