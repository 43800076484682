import { useState } from 'react';
const Ques1 = () => {
    const [que1, Setque1] = useState([]);
    const handleUpdate1 = () => {
        fetch('https://randomuser.me/api')
            .then((response) => response.json())
            .then((data) => {
                Setque1(data.results);
            });
        console.log("clicked");
    }
    return (
        <div className="container">
            <div className="row">
                <h1>Question1: Change current data on Button.</h1>
                <div className="col-sm-4"></div>
            {
                que1.map((que1one,i)=>{
                    return(
                        <div key={i} className="col-sm-4">
                            <div className="grid">
                            <img src={que1one.picture.large} /><br />
                                <p><span className="name">Name: </span> {que1one.name.title} {que1one.name.first} {que1one.name.last} </p>
                                <p><span className="name">Location:</span> {que1one.location.city} </p>
                                <p><span className="name">Country:</span> {que1one.location.country}</p>
                            </div>

                        </div>

                    );
                })

            }
            
                <input type="button" value="Fetch" onClick={handleUpdate1} />
                <div className="col-sm-4"></div>
        </div>
        </div>
        
        );
}
export default Ques1;