import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';
import React, { useReducer } from 'react';
import Home from './Home';
import Ques1 from './Question1';
import Ques2 from './Question2';
import Ques3 from './Question3';
import Show from './Show';
import Gmb from './Gmb';
import './App.css';
import GetAxios from './GetAxios';
import MockApitest from './MockApitest';
import MockapiresultData from './MockapiresultData';
export const Mycontext = React.createContext();
const is = { records: [] };
const reducer = (state, action) => {
    switch (action.type) {
        case "ADDED":
            const arr = [...state.records];
            arr.push(action.payload);
            return { records: arr };
        default : return state;
    }
    return state;
}
            

const App = () => {
    const [state, dispatch] = useReducer(reducer, is);
    console.log(state);
    const rcd = {
        state: state,
        dispatch: dispatch
    }
    return (
        <Mycontext.Provider value={rcd }>
            <Router>
                <div className="container-fluid">
                    <header className="d-flex flex-wrap justify-content-center py-3 mb-4 border-bottom">
                        <Link to="/" className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-dark text-decoration-none">

                            <span className="fs-4">API & ROUTING</span>
                        </Link>

                        <ul className="nav nav-pills">
                            <li className="nav-item">
                                <Link to="/" className="nav-link active" aria-current="page">Home</Link>
                            </li>
                            <li className="nav-item">
                                    <Link to="#" className="btn btn-secondary dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">fetch</Link>
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    <li className="nav-item">
                                        <Link to="/question1" className="nav-link">Question1</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/question2" className="nav-link">Question2</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/question3" className="nav-link">Question3</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/show" className="nav-link">Show Answer 3</Link>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-item">
                                    <Link to="#" className="btn btn-secondary dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">Axios</Link>
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    <li className="nav-item">
                                        <Link to="/getaxios" className="nav-link">GetAxios</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/mockapitest" className="nav-link">Mock API Test</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/mockapiresult" className="nav-link">Mock API Result</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/show" className="nav-link">Show Answer 3</Link>
                                    </li>
                                </ul>
                                </li>
                            <li className="nav-item">
                                <Link to="/gmb" className="nav-link">GMB</Link>
                            </li>
                        </ul>
                    </header>
                </div>
                <Switch>
                    <Route path="/" exact component={Home} />
                    <Route path="/question1" component={Ques1} />
                    <Route path="/question2" component={Ques2} />
                    <Route path="/question3" component={Ques3} />
                    <Route path="/show" component={Show} />
                    <Route path="/gmb" component={Gmb} />
                    <Route path="/getaxios" component={GetAxios} />
                    <Route path="/mockapitest" component={MockApitest} />
                    <Route path="/mockapiresult" component={MockapiresultData} />
                </Switch>
            </Router>
        </Mycontext.Provider>

    )
}
export default App;