import React, { useState, useEffect } from "react";
const Ques2 = () => {

    const [que2, Setque2] = useState([]);

    useEffect(() => {
        fetch('https://randomuser.me/api')
            .then((response) => response.json())
            .then((data) => {
                Setque2(data.results);
            });
    })
    return (
        <div className="container">
            <div className="row">
                <h1>Question 2</h1>
                <div className="col-sm-4"></div>
            {
                que2.map((que2one, i) => {
                    return (
                        <div key={i} className="col-sm-4">
                        <div className="grid">
                            <img src={que2one.picture.large} /><br />
                                <p><span className="name">Name: </span> {que2one.name.title} {que2one.name.first} {que2one.name.last} </p>
                                <p><span className="name">Location:</span> {que2one.location.city} </p>
                                <p><span className="name">Country:</span> {que2one.location.country}</p>
                        </div>
                        </div>

                    );
                })

            }
            <br />
                <div className="col-sm-4"></div>
            </div>
        </div>

    );

}
export default Ques2;