import React,{useState} from "react";
import axios from "axios";
const MockApitest=()=>{
//const [data,Setdata]=useState({nm:"",ag:"",em:""}); 
const [name,Setname]=useState(''); 
const [age,Setage]=useState(''); 
const [email,Setemail]=useState(''); 
const [response,Setresponsemsg]=useState('');
const RecordValue=(evt)=>{
    switch(evt.target.name){
        case "nm":
            //Setdata({...data,nm:evt.target.value});
            Setname(evt.target.value);
            break;
        case "ag":
            //Setdata({...data,ag:evt.target.value});
            Setage(evt.target.value);
            break;
        case "em":
            //Setdata({...data,em:evt.target.value});
            Setemail(evt.target.value);
            break;
    }
}
//console.log(data);
const submitData=(e)=>{
    e.preventDefault();
    axios.post('https://63382d70132b46ee0beb8ecf.mockapi.io/Students',{
        //data:data
        name:name,
        age:age,
        email:email
    })
    .then((response)=>{
        console.log(response);
        Setresponsemsg("Your data has been submitted, We will get back to you asap.")
        Setname("");
        Setage("");
        Setemail("");
        // setTimeout(() => {
        //     form.submit();
        // }, 1000);
    })
    .catch((error)=>{
        console.log(error);
    })
}
    return(
        <>
            <h1>Mock API Test Question</h1>
            <form method="POST" action="/mockapiresult">
                <input type="text" placeholder="Enter your Name" name="nm" value={name} onChange={RecordValue} required/><br/>
                <input type="number" placeholder="Enter your Age" name="ag" value={age} onChange={RecordValue}  required/><br/>
                <input type="email" placeholder="Enter your Email" name="em"  value={email} onChange={RecordValue}  required/><br/>
                <input type="submit" value="Submit" onClick={submitData}/>
                <p>{response}</p>
            </form>
        </>
    );
}
export default MockApitest;