import { useState,useContext } from 'react';
import { Mycontext } from './App';
const Ques3 = () => {
    const rcd = useContext(Mycontext);
    const {state, dispatch} = rcd;
    const [que3, Setque3] = useState([]);
    const [number, Setnumber] = useState();
    const ValueChange = (evt) => {
        Setnumber(evt.target.value);
    }
    const handleUpdate3 = () => {
        fetch('https://randomuser.me/api?results=' + number)
            .then((response) => response.json())
            .then((data) => {
                Setque3(data.results);
                dispatch({ type: "ADDED", payload: data.results });
                
            });
    }
    return (
        <div className="container">
            <h1>Question 3</h1>
            <input type="text" name="get_number" placeholder="Enter no" onChange={ValueChange} />

                <input type="button" value="Fetch User" onClick={handleUpdate3} className="fetch" />
                <br />
            <div className="row">

            {
                que3.map((que3one, i) => {
                    return (
                        <div key={i} className="col-sm-3">
                            <div className="grid">
                            <img src={que3one.picture.large} /><br />
                                <p><span className="name">Name: </span> {que3one.name.title} {que3one.name.first} {que3one.name.last} </p>
                                <p><span className="name">Location:</span> {que3one.location.city} </p>
                                <p><span className="name">Country:</span> {que3one.location.country}</p>
                        </div>
                        </div>

                    );
                })

            }
            <br />
            </div>
        </div>

    );
}
export default Ques3;