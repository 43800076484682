
import React, { useState } from 'react';
import axios  from "axios";


const GetAxios=()=>{
    const [loc, Setloc] = useState([]);
    const ValueChange=(evt)=>{
        evt.preventDefault();
     
        let formData = new FormData();
        formData.append('data', evt.target.value)
      // axios.post('http://api.adortatechnologies.com/token/index.php', {
        //     axios.post('http://localhost/Gmb/', {
        //     d: evt.target.value,
        //   })
          axios({
            method: 'post',
            url: 'http://api.adortatechnologies.com/token/index.php',
            data: formData,
        })
          .then(function (response) {
            let l=JSON.parse(response.data);
            
            let location=l.locationDrivingDirectionMetrics[0].topDirectionSources[0].regionCounts;
            Setloc(location);
            //  dispatch({ type: "ADDED", payload: data.results });
        })
        .catch(function (error) {
            console.log(error);
        });
    }
   console.log(loc);
    return(
        <div className="container">
            <div className="row">
                <h1>Hello from Get Axios</h1>
                <div >
                <form>
                    <select onChange={ValueChange} name="data">
                        <option defaultValue>--choose --</option>
                        <option value="SEVEN">1 Week</option>
                        <option value="THIRTY">1 Month</option>
                        <option value="NINETY">1 Quater</option>
                    </select>

                </form>
                </div>
                {
                    loc.map((locations, i) => {
                        return (
                            <div key={i} className="col-sm-3">
                                <div className="grid">
                                    pincode:{locations.label} <br />
                                    <p>  count: {locations.count}</p>
                                </div>
                            </div>

                        );
                    })

                }
            </div>
        </div>
        
    )
}
export default GetAxios;