import axios from "axios";
import { useState,useEffect } from "react";
const MockapiresultData=()=>{
    const [data,Setdata]=useState([]);
    useEffect(()=>{
        axios.get('https://63382d70132b46ee0beb8ecf.mockapi.io/Students')
        .then((response)=>{
            Setdata(...data,response.data);
        })
        .catch((error)=>{
            console.log(error);
        })
        
    },[])
    console.log(data);
    return(
        <div className="container">
        <div className="row">
         <h1>Show Added Data</h1>
        {
            data.map((locations, i) => {
            return(
                
                <div key={i}  className="col-sm-4">
                <div className="grid">
                Name: {locations.name}<br/>
                    ID:{locations.id}<br/>
                    Age:{locations.age}<br/>
                    Email:{locations.email}<br/>
                </div>

            </div>
            )
         })
            
        }
     </div>
     </div>
    )
 }
 export default MockapiresultData;

  

