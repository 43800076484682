import { useState } from 'react';
import './Home.css';
const Home = () => {
    const [data, Setdata] = useState([]);
    const handleUpdate = () => {
        fetch('https://randomuser.me/api?results=8')
            .then((response) => response.json())
            .then((data) => {
                Setdata(data.results);
            });
    }
    return (
        <div className="container">
            <div className="row">
            <h1>Fetch Multiple User Data</h1>
            <div >Click here : <input type="button" value="Fetch" onClick={handleUpdate} />
            </div>
           
            {
                data.map((dataone, i) => {
                    return (
                        <div key={i} className="col-sm-3">
                            <div className="grid">
                                <img src={dataone.picture.large} /><br />
                                <p><span className="name">Name: </span> {dataone.name.title} {dataone.name.first} {dataone.name.last} </p>
                                <p><span className="name">Location:</span> {dataone.location.city} </p>
                                <p><span className="name">Country:</span> {dataone.location.country}</p>
                            </div>
                        </div>
                    );
                })
            }
            </div>
        </div>
    )
}
export default Home;