import { useContext } from 'react';
import { Mycontext } from './App';
const Show = () => {
    const rcd = useContext(Mycontext);
    const { state, dispatch } = rcd;
    return (
        <div className="container">
            <div className="row">
            {
                state.records[0].map((que3one, i) => {
                    return (
                        <div key={i} className="col-sm-4">
                            <div className="grid">
                            <img src={que3one.picture.large} /><br />
                            Name: {que3one.name.title} {que3one.name.first} {que3one.name.last}<br />
                            Location: {que3one.location.city} <br />
                            Country: {que3one.location.country}<br />
                        </div>
                        </div>

                    );
                })

            }
            </div>
        </div>
    );
}
export default Show;